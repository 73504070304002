export default function PrivacyPolicy() {
    return (
      <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
        <div className="absolute inset-0 -z-10 overflow-hidden">
          <svg
            className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
            aria-hidden="true"
          >
            <defs>
              <pattern
                id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                width={200}
                height={200}
                x="50%"
                y={-1}
                patternUnits="userSpaceOnUse"
              >
                <path d="M100 200V.5M.5 .5H200" fill="none" />
              </pattern>
            </defs>
            <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
              <path
                d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                strokeWidth={0}
              />
            </svg>
            <rect
              width="100%"
              height="100%"
              strokeWidth={0}
              fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
            />
          </svg>
        </div>
        <div className="mx-auto max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="">
                <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Privacy Policy
                </h1>
              </div>
            </div>
          </div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div className="lg:pr-4">
              <div className="text-base leading-7 text-gray-700 ">
              <p className="text-lg font-bold mt-6">Information We Collect</p>
                <p>

We may collect personal information that you provide to us when you:

register for course availability monitoring, or contact us for support or inquiries.

The types of personal information we may collect include:

Name, Email address, and University related information (for example, which courses you would like to monitor)
</p>
<br/>
<p className="text-lg font-bold">How We Use Your Information</p>

<p>
We may use the personal information we collect for various purposes, including to:

provide, maintain, and improve our services, 
process transactions and fulfill orders, 
communicate with you about your account or our services, 
send you promotional offers and marketing communications, 
respond to your inquiries and provide support
</p>
<br/>
<p className="text-lg font-bold">Information Sharing and Disclosure</p>
<p>
We may share your personal information with:

service providers who assist us in operating our website and providing services, 
third-party vendors and partners who provide services on our behalf, 
law enforcement or regulatory authorities in response to lawful requests or legal process, 
other parties with your consent or as required by law, 
</p>
<br/>
<p className="text-lg font-bold">Data Retention</p>
<p>
We will retain your personal information only for as long as necessary to fulfill the purposes outlined in this Privacy Policy unless a longer retention period is required or permitted by law.
</p>
<br/>
<p className="text-lg font-bold">Security</p>
<p>
We take reasonable measures to protect the confidentiality and security of your personal information. However, no method of transmission over the internet or electronic storage is completely secure.
</p>
<br/>
<p className="text-lg font-bold">Your Rights</p>
<p>
You may have certain rights regarding your personal information, including the right to access, correct, or delete your information. Please contact us if you would like to exercise these rights.
</p>
<br/>
<p className="text-lg font-bold">Changes to This Privacy Policy</p>
<p>
We may update this Privacy Policy from time to time. Any changes will be posted on this page, and the "Last Updated" date will be revised accordingly.
</p>
<br/>
<p className="text-lg font-bold">Contact Us</p>
<p>
If you have any questions or concerns about this Privacy Policy, please contact us at opencoursenotifyme@gmail.com.
</p>
<br/>
<br/>
<p >Last Updated: March 18 2024</p>

              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  