export default function TermsOfService() {
  return (
    <div className="relative isolate overflow-hidden bg-white px-6 py-24 sm:py-32 lg:overflow-visible lg:px-0">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-gray-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect
            width="100%"
            height="100%"
            strokeWidth={0}
            fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)"
          />
        </svg>
      </div>
      <div className="mx-auto max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10">
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="">
              <h1 className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Terms of Service
              </h1>
            </div>
          </div>
        </div>
        <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
          <div className="lg:pr-4">
            <div className="text-base leading-7 text-gray-700 ">
              <ul className="mt-8 space-y-8 text-gray-600">
                <li className="flex gap-x-3">
                  The service is not officially affiliated with any educational
                  institution.
                </li>
                <li className="flex gap-x-3">
                  The offered service does not guarantee enrollment into a
                  course, rather it runs on a schedule to periodically check
                  whether a spot in the course or on the
                  waitlist opened up, and if so it attempts to notify users via email.
                </li>
                <li className="flex gap-x-3">
                  The service relies on data provided by the university.
                  Therefore, no guarantees can be made for cases where that data
                  is stale or where the university does not update their data
                  correctly or in a timely manner. The service is responsible
                  for reading the availability data as presented by the university and
                  notifying users who would otherwise manually read the same
                  data. It cannot account for any restricted
                  or reserved seats you specifically may or may not be a part of.
                </li>
                <li className="flex gap-x-3">
                  The service may halt operations at any given time if deemed
                  necessary. Existing transactions can't be refunded.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
