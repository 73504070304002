import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { universities } from "./universities";
import Loader from "./Loader";

export default function UniversityValidator({ children }) {
  const navigate = useNavigate();
  const { university } = useParams();
  const [isValidUniversity, setIsValidUniversity] = useState(false);

  useEffect(() => {
    if (!universities[university]) {
      navigate("/");
    } else {
      setIsValidUniversity(true);
    }
  }, [university, navigate]);

  return isValidUniversity ? children : <Loader />;
}
