// FAQSection.js
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/20/solid";

export default function FAQ({university}) {
    console.log(university)
    const faqs = [
        {
          question: 'How does it work?',
          answer:
            'Our technology constantly checks the data the university shares on a schedule. If it sees that a new a spot has become available in the course and sections you are interested in, whether in the class itself or on the waitlist, an email gets sent to you.',
        },
        {
            question: 'My university has waitlists, how is this different?',
            answer:
              'In many universities, not all the classes will have waitlists. Further, for some classes that do have waitlists, they may usually be full, making it very hard to even get onto the waitlist; this tool helps in those cases as well. So this tool is for you if the class you want to get into has no waitlist or has a full waitlist, helping you get into the class or onto its waitlist.',
          },
        {
          question: 'Why is it paid?',
          answer: 'As you might imagine, there are technical infrastructure costs to frequently check the course availablity data for all the students who want help getting into their courses. There are also maintenance and developer costs. However, we have tried to make the pricing reasonable; the price of a coffee to help you get into a course or section that could enable you to graduate on time, or be with a professor that could make the difference between an A or a B grade, or to get the schedule most convenient to you.',
        },
        {
          question: 'How is my payment information handled?',
          answer: <>We do not handle your payment information. We use <a className="font-bold" href='https://stripe.com'>Stripe</a>, which is the leading processor of online payments globally. We redirect you to their website at checkout and they securely handle all payment related operations. As a result, we do not have access to your payment information.</>
        },
        {
          question: 'I received a notification, but there were no spots when I checked or I could not enroll into it?',
          answer: <>    <p>This can happen and is most likely due to someone getting to the spot before you and registering first. No worries, this likely won't be the last time a spot opens up :{")"}<br/>
           <br/>Note that email notifications are sent to Unlimited members first then Single members right after.
          </p><br/>
          {university === "ucalgary" && <p>
          Another possible reason is if you are monitoring a section during a period of time where certain seats are reserved for a group you are not part of (e.g CPSC majors only). In such a scenario, you may receive a false positive notification since there is actually an available spot and since it is not possible to account for whether you specifically are eligible for that spot. Once the reserved seats timeline ends, you should be eligible for any open spots you receive a notification for.  
          </p>}
          </>
        }
        // Add more FAQs as needed
      ];

  return (
    <div className="mt-20">
      <h2 className="text-lg font-semibold text-gray-800 mb-6">Frequently Asked Questions</h2>
      <div className="accordion">
        {faqs.map((faq, index) => (
          <Disclosure key={index}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between w-full p-4  focus:outline-none focus:ring focus:ring-blue-200">
                  <span className="font-semibold text-left	">{faq.question}</span>
                  {open ? <ChevronUpIcon className="w-6 h-6 text-gray-600" /> : <ChevronDownIcon className="w-6 h-6 text-gray-600" />}
                </Disclosure.Button>
                <Disclosure.Panel className="p-4 text-gray-700">
                  {faq.answer}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}
