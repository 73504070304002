import * as yup from "yup";

const defaultCourseRegex = "^[a-zA-Z]+\\s[^#\\s,]+(\\s)*$";
const defaultCourseComponentRegex = "^[^-,#]+$";
const defaultCourseFormatMessage = `You must specify a course in the format "SUBJECT NUMBER"`;
const defaultCourseComponentFormatMessage = `Specify one course component section per input field in the same format as listed in your schedule builder`;

const buildDisallowedEmailRegex = (emailDomains) => {
  return `^((?!@${emailDomains.join("|")}).)*$`;
};

const buildDisallowedEmailMessage = (emailDomains) => {
  return `You must provide a valid non @${emailDomains.join(
    ", @"
  )} email in order to be notified`;
};

const fromBaseSchema = ({
  universityEmailDomains,
  emailRegex = buildDisallowedEmailRegex(universityEmailDomains),
  courseRegex = defaultCourseRegex,
  courseComponentRegex = defaultCourseComponentRegex,
  emailFormatMessage = buildDisallowedEmailMessage(universityEmailDomains),
  courseFormatMessage = defaultCourseFormatMessage,
  courseComponentFormatMessage = defaultCourseComponentFormatMessage,
}) => {
  return yup
    .object()
    .shape({
      course: yup
        .string()
        .matches(new RegExp(courseRegex), courseFormatMessage)
        .required(courseFormatMessage),
      courseComponents: yup
        .array()
        .of(
          yup.object().shape({
            componentId: yup
              .string()
              .matches(
                new RegExp(courseComponentRegex),
                courseComponentFormatMessage
              )
              .required(courseComponentFormatMessage),
          })
        )
        .min(1, "At least one course component is required"),

      email: yup
        .string()
        .email()
        .matches(new RegExp(emailRegex, 'i'), emailFormatMessage)
        .required(emailFormatMessage),
    })
    .required();
};

const ucalgary = fromBaseSchema({ universityEmailDomains: ["ucalgary"] });
const uhouston = fromBaseSchema({ universityEmailDomains: ["uh"] });
const csub = fromBaseSchema({ universityEmailDomains: ["csub"] });
const csuf = fromBaseSchema({ universityEmailDomains: ["csu.fullerton"] });
const csueb = fromBaseSchema({
  universityEmailDomains: ["horizon.csueastbay"],
});
const csumb = fromBaseSchema({ universityEmailDomains: ["csumb"] });
const missourist = fromBaseSchema({
  universityEmailDomains: ["mst", "umsystem"],
});
const mizzou = fromBaseSchema({ universityEmailDomains: ["umsystem"] });
const umkc = fromBaseSchema({ universityEmailDomains: ["umsystem"] });
const umsl = fromBaseSchema({ universityEmailDomains: ["umsl", "umsystem"] });
const sfu = fromBaseSchema({ universityEmailDomains: ["sfu"] });
const concordia = fromBaseSchema({
  universityEmailDomains: ["live.concordia", "mail.concordia"],
});
const uottawa = fromBaseSchema({ universityEmailDomains: ["uottawa"] });
const uoguelph = fromBaseSchema({ universityEmailDomains: ["uoguelph"] });
const carleton = fromBaseSchema({universityEmailDomains: ["cmail.carleton"]})

export const schemas = {
  ucalgary,
  uhouston,
  csub,
  csuf,
  csueb,
  csumb,
  missourist,
  mizzou,
  umkc,
  umsl,
  sfu,
  concordia,
  uottawa,
  uoguelph,
  carleton
};
