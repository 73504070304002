import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { universities } from "./universities";

export default function Refer() {
  const location = useLocation();
  const registration = location.state;
  console.log(registration);
  const emailDomains = universities[registration.university].emailDomains;
  const emailOptions = emailDomains.join("/");
  const emailValidationMessage = `You must provide a valid @${emailOptions} email.`;
  const validation = yup
    .string()
    .email(emailValidationMessage)
    .matches(
      new RegExp(`^.+@(${emailDomains.join("|")}).+$`),
      emailValidationMessage
    )
    .required(emailValidationMessage);
  const schema = yup
    .object()
    .shape({
      referrerEmail: validation,
      refereeEmail: validation,
    })
    .required();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState();
  const [isReferralComplete, setIsReferralComplete] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.state) navigate("/");
  });

  console.log(location.state);

  const onSubmit = async (data) => {
    console.log(data);
    setIsLoading(true);

    try {
      const response = await fetch(process.env.REACT_APP_REFER_FUNCTION_URL, {
        method: "POST",
        body: JSON.stringify({
          ...registration,
          referrer: data.referrerEmail.trim(),
          referee: data.refereeEmail.trim(),
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      let responseData = await response.json();
      console.log(response);
      console.log(responseData);

      if (response.ok && !response.headers.has("X-Amz-Function-Error")) {
        setIsReferralComplete(true);
      } else {
        if (responseData.errorCode === "ALREADY_REFERRED") {
          setHasError(responseData.errorMessage);
        } else {
          throw new Error("Error referring");
        }
      }
    } catch (e) {
      setHasError("Something went wrong, please try again.");
      console.log(e);
    }
    setIsLoading(false);
  };

  const onErrors = (data) => {
    console.log(data);
  };

  return isReferralComplete ? (
    <div className="bg-[#FAF7F0] ">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="h-screen flex justify-center items-center mx-auto max-w-4xl ">
          <div className="text-center">
            <p className="text-[#116A7B] font-bold text-3xl">
              Referral in progress
            </p>
            <p className="mt-6 text-lg font-medium text-gray-900 text-3xl">
              Let your friend know that they should receive an email that they
              must confirm in order for you to confirm your email and complete
              the referral.
            </p>
            <p className="mt-6 text-lg font-medium text-gray-900 text-3xl">
              Some university mailing systems tends to quarantine emails
              therefore there may be a delay and the email may likely be in the
              spam folder. If any delay in confirmation emails takes over 48
              hours, send us an email at opencoursenotifyme@gmail.com from your
              university email that you just provided and we'll manually confirm
              your referral.
            </p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-[#FAF7F0] flex flex-col min-h-screen">
      <div className="relative isolate px-6 pt-14 lg:px-8">
        <div className="mx-auto max-w-2xl">
          <div className=" mb-5 ">
            <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
              Refer a friend
            </h2>
            <p className="mt-6 text-lg leading-8 text-gray-600">
              Specify you and your friend's <b>university</b> emails (
              {emailDomains.map((domain) => `@${domain}`).join(" or ")}) then:
            </p>
            <br />
            <ol className="ml-3 list-decimal leading-8 text-gray-600">
              <li>
                {" "}
                We send your friend an email with a confirmation link that they{" "}
                <b>must</b> visit to confirm the referral.
              </li>
              <li>
                After they confirm, we send you a email that you <b>must</b>{" "}
                also confirm to validate that it is in fact your email.
              </li>
              <li> We start monitoring your course selection for free.</li>
              <li>
                Your friend is also be able to monitor a free course of their
                choice.
              </li>
            </ol>
          </div>
          <div>
            <form onSubmit={handleSubmit(onSubmit, onErrors)}>
              {errors.term && (
                <p className="mt-2 text-red-400">You must specify a term.</p>
              )}
              <div className="mt-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Your university email
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="referrerEmail"
                    id="referrerEmail"
                    className="block w-full rounded-md border-0 py-1.5 pl-3 lg:pl-5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#116A7B] sm:text-sm sm:leading-6"
                    placeholder={`@${emailOptions}`}
                    {...register("referrerEmail")}
                  />
                </div>
                {errors.referrerEmail && (
                  <p className="mt-2 text-red-400">
                    {errors.referrerEmail.message}
                  </p>
                )}
              </div>
              <div className="mt-3">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Friend's university email
                </label>
                <div className="relative mt-2 rounded-md shadow-sm">
                  <input
                    type="text"
                    name="refereeEmail"
                    id="refereeEmail"
                    className="block w-full rounded-md border-0 py-1.5 pl-3 lg:pl-5 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#116A7B] sm:text-sm sm:leading-6"
                    placeholder={`@${emailOptions}`}
                    {...register("refereeEmail")}
                  />
                </div>
                {errors.refereeEmail && (
                  <p className="mt-2 text-red-400">
                    {errors.refereeEmail.message}
                  </p>
                )}
              </div>

              <div className="mt-5">
                <button
                  disabled={isLoading}
                  className="mb-1 text-center block w-full rounded-md bg-[#116A7B] px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-[#116A7B] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-[#116A7B]"
                  type="submit"
                >
                  {isLoading ? (
                    <svg
                      aria-hidden="true"
                      role="status"
                      className="inline mr-3 w-4 h-4 text-white animate-spin"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="#E5E7EB"
                      ></path>
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  ) : (
                    <> Submit</>
                  )}
                </button>
              </div>
              {hasError && <p className="mt-3 text-red-400">{hasError}</p>}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
