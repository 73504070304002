import { useSearchParams, useNavigate } from 'react-router-dom';

export function useQueryParams() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const navigateWithAddedParams = (to, state = {}) => {
    navigate(`${to}&${searchParams.toString()}`, state);
  };

  const navigateWithExistingParams = (to, state = {}) => {
    navigate(`${to}?${searchParams.toString()}`, state);
  };

  const getUtmParams = () => {
    const utmParams = [];
    searchParams.forEach((value, key) => {
      if (key.startsWith("utm_")) {
        utmParams.push(`${key}=${value}`);
      }
    });
    return utmParams;
  };

  return { navigateWithAddedParams, navigateWithExistingParams, getUtmParams };
}