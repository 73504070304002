import {
  Link,
  useSearchParams,
} from "react-router-dom";

export default function CustomLink({ to, children, ...rest }) {
    const [searchParams] = useSearchParams();
    //assumes consumer wil not pass in to that has query params..
    const target = `${to}?${searchParams.toString()}`;
  
    return <Link to={target} {...rest}>{children}</Link>;
  }